/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useMemo, useState } from 'react';
// import dayjs from 'dayjs';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';

import { Button, Toast } from '@/components';
// import { DATE_FORMAT_WITH_POINT, TIME_FORMAT_WITHOUT_SEC, WEEKS_FORMAT } from '@/utils/constants';
import { leaveApply } from '@/api/curriculum';

import styles from './style.module.scss';
import { LeaveProps } from '../../Home/interface';
import { log } from '@/api/log';
import { toDay, toHour } from '@/utils/utils';
import VerticalDivider from '@/pageComponents/Common/VerticalDivider';

const AdjustApplyComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const {
    type,
    classType,
    lessonTitle,
    lessonUnitTitle,
    courseScheduleId = '',
    lessonTeacherName,
    lessonStartDateTime,
    lessonEndDateTime,
    gtmPrefix,
    onClose,
  } = props;

  const submitDis = useMemo(() => {
    return !reason?.trim();
  }, [reason]);

  // const time = useMemo(() => {
  //   const date = dayjs(lessonStartDateTime).format(DATE_FORMAT_WITH_POINT);
  //   const week = t(WEEKS_FORMAT[dayjs(lessonStartDateTime).day()]);
  //   const startTime = dayjs(lessonStartDateTime).format(TIME_FORMAT_WITHOUT_SEC);
  //   const endTime = dayjs(lessonEndDateTime).format(TIME_FORMAT_WITHOUT_SEC);
  //   return `${date} ${week} ${startTime}-${endTime}`;
  // }, [lessonStartDateTime, lessonEndDateTime]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      gtmPrefix && log(gtmPrefix`adjust_apply`);
      await leaveApply({ courseScheduleId, reason, acceptReschedule: true });
      gtmPrefix && log(gtmPrefix`adjust_success`);
      setLoading(false);
      Toast.success(t('调课申请提交成功'));
      onClose();
    } catch {
      setLoading(false);
    }
  }, [courseScheduleId, reason, type, gtmPrefix]);

  const boxItemWidth = i18n.language === 'en' ? '90px' : 'auto';

  return (
    <div className={styles.boxWrapper}>
      <div className={cls(styles.boxItem, styles.mT20)}>
        <span style={{ width: boxItemWidth }}>{t('请假课程')}:</span>
        <span className={styles.itemTip}>
          {lessonUnitTitle?.split('-').join(' ')} - {lessonTitle}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('课程类型')}: </span>
        <span className={styles.itemTip}>{classType}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('主讲老师')}:</span>
        <span className={styles.itemTip}>{lessonTeacherName}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('上课时间')}:</span>
        <span className={cls(styles.itemTip, styles.bold)}>
          {toHour(lessonStartDateTime as string)} - {toHour(lessonEndDateTime as string)}
          <VerticalDivider />
          {toDay(lessonStartDateTime as string, i18n.language)}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }} className={styles.multipleLine}>
          {t('调课规则')}:
        </span>
        <div className={styles.itemTip}>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('调课规则提示1') }} />
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('调课规则提示2') }} />
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('调课规则提示3') }} />
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('调课规则提示4') }} />
        </div>
      </div>
      <div className={styles.line} />
      <div className={cls(styles.boxItem, styles.mB0)}>
        <span style={{ width: boxItemWidth }} className={styles.import}>
          {t('调课申请')}:
        </span>
        <textarea
          onInput={({ currentTarget }) => setReason(currentTarget.value)}
          className={cls(styles.textarea, 'scrollbar')}
          maxLength={200}
          placeholder={t('调课申请占位')}
        />
      </div>
      <div className={styles.inputWrapper}>
        {/* <span className={styles.inputTitle}>{t('调课申请')}:</span>
        <textarea
          onInput={({ currentTarget }) => setReason(currentTarget.value)}
          className={cls(styles.textarea, 'scrollbar')}
          maxLength={200}
          placeholder={t('调课申请占位')}
        /> */}
        <div className={styles.btnGroup}>
          <Button onClick={onClose} className={cls(styles.button, styles.cancel)}>
            {t('取消')}
          </Button>
          <Button
            onClick={handleSubmit}
            loading={loading}
            disabled={submitDis}
            color="red"
            shieldIcon
            className={cls(styles.button, styles.submit)}
          >
            {t('提交申请')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdjustApplyComponent;
