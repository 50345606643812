import React, { CSSProperties, useEffect } from 'react';
import ReactDOM from 'react-dom';
// import { useTranslation } from 'next-i18next';

import { CSSTransition } from 'react-transition-group';

import Image from 'next/image';

import closeSvg from '@public/imgs/pc/close-popup.svg';

import styles from './style.module.scss';

interface Props {
  title?: string;
  customTitle?: React.ReactNode;
  visible: boolean;
  disableBodyScroll?: boolean;
  container?: Element;
  onClose?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
  width?: number;
  contentStyle?: CSSProperties;
  modalStyle?: CSSProperties;
}

const Modal = (props: Props) => {
  const {
    title,
    customTitle,
    visible = false,
    disableBodyScroll = false,
    container = document.body,
    onClose,
    children,
    contentStyle,
    modalStyle,
  } = props || {};

  useEffect(() => {
    if (visible) {
      const overflowBuffer = document.body.style.overflow;
      if (disableBodyScroll) {
        document.body.style.overflow = 'hidden';
      }
      return (): void => {
        document.body.style.overflow = overflowBuffer;
      };
    }
    return () => {
      // nothing return
    };
  }, [disableBodyScroll, visible]);

  return ReactDOM.createPortal(
    <CSSTransition
      in={visible}
      timeout={200}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
      }}
      unmountOnExit
    >
      <div className={styles.modalWrapper} onClick={onClose}>
        <section
          style={{ width: `${props.width}px` || '640px', ...modalStyle }}
          onClick={(e) => e.stopPropagation}
          className={styles.modal}
        >
          {customTitle ? (
            <div onClick={(event) => event.stopPropagation()}>{customTitle}</div>
          ) : (
            <div className={styles.title} onClick={(event) => event.stopPropagation()}>
              {title}
              <Image
                className={styles.close}
                src={closeSvg}
                onClick={(e) => {
                  e.stopPropagation();
                  // @ts-ignore
                  onClose();
                }}
              />
            </div>
          )}
          <div style={contentStyle} onClick={(event) => event.stopPropagation()} className={styles.contentWrapper}>
            {children}
          </div>
        </section>
      </div>
    </CSSTransition>,
    container,
  );
};

export default Modal;
