import React from 'react';
import cls from 'classnames';
import Image from 'next/image';

import loadingIcon from '@public/imgs/loading.svg';
import { i18n } from 'next-i18next';
import styles from './style.module.scss';

export enum TypeButton {
  RED = 'red',
  PRIMARY = 'primary',
  DEFAULT = 'default',
  CANCEL = 'cancel',
}

interface Props {
  type?: `${TypeButton}`;
  className?: string;
  processing?: boolean;
  text: string;
  loading?: boolean;
  onClick?: () => void;
}
const NewButton = (props: Props) => {
  const { className, processing = false, type = TypeButton.DEFAULT, text, loading, onClick } = props;

  return (
    <span
      role="button"
      className={cls(
        styles.button,
        i18n?.language === 'zh' && styles.zh,
        { [styles.processing]: processing },
        { [styles.default]: type === TypeButton.DEFAULT },
        { [styles.redType]: type === TypeButton.RED },
        { [styles.primary]: type === TypeButton.PRIMARY },
        { [styles.cancel]: type === TypeButton.CANCEL },
        className,
      )}
      onClick={onClick}
    >
      {loading && (
        <div className={styles.loading}>
          <Image width={24} src={loadingIcon} />
        </div>
      )}
      {text}
    </span>
  );
};

export default NewButton;
