import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';

import successIcon from '@public/imgs/pc/icon-success-fill.svg';
import processIcon from '@public/imgs/icon-warning-new.svg';
import failIcon from '@public/imgs/pc/icon-error.svg';
// import { Button } from '@/components';
import styles from './style.module.scss';
import { LeaveProps, AdjustResultEnum } from '../../Home/interface';
// import { getFormatStartTime } from '../../Home/util';
import { toDay, toHour } from '@/utils/utils';

const ADJUST_RESULT_LABEL = {
  [AdjustResultEnum.PROCESS]: {
    title: '调课中',
    label: '调课中提示',
    icon: processIcon.src,
  },
  [AdjustResultEnum.SUCCESS]: {
    title: '调课成功',
    label: '调课成功提示',
    icon: successIcon.src,
  },
  [AdjustResultEnum.FAIL]: {
    title: '调课未通过',
    label: '调课未通过提示',
    icon: failIcon.src,
  },
};

const LeaveRecordComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const {
    classType,
    reason,
    lessonTitle,
    lessonUnitTitle,
    lessonTeacherName,
    lessonStartDateTime = '',
    lessonEndDateTime = '',
    adjustStatus = AdjustResultEnum.PROCESS,
    // onClose,
    whenCreated,
  } = props;

  const currentLabel = useMemo(() => {
    return ADJUST_RESULT_LABEL[adjustStatus];
  }, [adjustStatus]);

  const boxItemWidth = i18n.language === 'en' ? '90px' : 'auto';

  return (
    <div className={styles.boxWrapper}>
      <div
        className={cls(styles.boxHeader, {
          [styles.success]: adjustStatus === AdjustResultEnum.SUCCESS,
          [styles.processing]: adjustStatus === AdjustResultEnum.PROCESS,
          [styles.failed]: adjustStatus === AdjustResultEnum.FAIL,
        })}
      >
        <div className={styles.headerIcon}>
          <img src={currentLabel.icon} alt="" />
        </div>
        <div className={styles.headerRight}>
          <div
            className={cls(styles.headerTitle, {
              [styles.success]: adjustStatus === AdjustResultEnum.SUCCESS,
              [styles.processing]: adjustStatus === AdjustResultEnum.PROCESS,
              [styles.failed]: adjustStatus === AdjustResultEnum.FAIL,
            })}
          >
            {t(currentLabel.title)}
            {adjustStatus !== AdjustResultEnum.PROCESS && '!'}
          </div>
          <span className={styles.headerSubTitle} dangerouslySetInnerHTML={{ __html: t(currentLabel.label) }} />
        </div>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假课程')}: </span>
        <span className={styles.itemTip}>
          {lessonUnitTitle?.split('-').join(' ')} - {lessonTitle}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('课程类型')}: </span>
        <span className={styles.itemTip}>{classType}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('主讲老师')}:</span>
        <span className={styles.itemTip}>{lessonTeacherName}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('上课时间')}:</span>
        <span className={cls(styles.itemTip, styles.bold)}>
          {toDay(lessonStartDateTime, i18n.language, 'YYYY-M-D (dddd)')} {toHour(lessonStartDateTime)}-
          {toHour(lessonEndDateTime)}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假时间')}:</span>
        <span className={styles.itemTip}>
          {toDay(whenCreated || '', i18n.language, 'YYYY-M-D (dddd)')} {toHour(whenCreated || '')}
        </span>
      </div>
      <div className={styles.line} />
      <div className={cls(styles.boxItem, styles.mB0)}>
        <span style={{ width: boxItemWidth }}>{t('调课申请')}:</span>
        <span className={styles.itemTip}>{reason}</span>
      </div>
      {/* <div className={styles.btnGroup}>
        <Button onClick={onClose} className={cls(styles.button, styles.close)}>
          {t('关闭')}
        </Button>
      </div> */}
    </div>
  );
};

export default LeaveRecordComponent;
