import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Modal, Toast } from '@/components';
import NewButton from '@/components/NewButton';
import styles from './style.module.scss';
import modalStyles from '../Status/style.module.scss';
import { LeaveApplyRes } from '@/api/types/curriculum';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  leaveApplySer: () => Promise<LeaveApplyRes>;
}

const UrgentLeaveConfirm = (props: Props) => {
  const { visible, onClose, onSubmit, leaveApplySer } = props;
  const { t } = useTranslation('home');
  const [specialUrgentLoading, setSpecialUrgentLoading] = useState(false);

  return (
    <Modal
      className={modalStyles.alert}
      bodyClassName={styles.urgentLeaveConfirmModal}
      visible={visible}
      closeBtn={false}
    >
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.modalTitle}>{t('紧急请假提示')}</div>
        <div
          className={modalStyles.modalTip}
          dangerouslySetInnerHTML={{
            __html: t('紧急请假说明'),
          }}
        />
        <div className={modalStyles.modalSubTip}>
          <span className={modalStyles.tip}>{t('紧急请假温馨提示')}</span>
        </div>
        <div className={modalStyles.btnGroup}>
          <NewButton
            onClick={() => {
              setSpecialUrgentLoading(false);
              onClose();
            }}
            className={modalStyles.cancel}
            text={t('我在想想')}
          />
          <NewButton
            loading={specialUrgentLoading}
            type="red"
            onClick={() => {
              setSpecialUrgentLoading(true);
              leaveApplySer()
                .then(() => {
                  Toast.success(t('请假申请提交成功'));
                  onSubmit();
                })
                .finally(() => {
                  setSpecialUrgentLoading(false);
                });
            }}
            className={modalStyles.submit}
            text={t('确定请假')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UrgentLeaveConfirm;
