import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import type { DebouncedFunc, DebounceSettings } from 'lodash';

const useDebounce = <T extends (...args: any) => any>({
  func,
  wait,
  options,
  deps,
}: {
  func: T;
  wait?: number;
  options?: DebounceSettings;
  deps?: any[];
}): DebouncedFunc<T> => {
  const debounceFuncRef = useRef<T>(func);

  useEffect(() => {
    debounceFuncRef.current = func;
  }, [func]);

  return useMemo(() => {
    return debounce(
      (...args: any[]) => {
        debounceFuncRef.current(...args);
      },
      wait,
      options,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wait, options, ...(deps || [])]);
};

export default useDebounce;
