/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useMemo, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import promptIconWarning from '@public/imgs/pc/icon-prompt-warning.svg';
import { Button, Toast } from '@/components';
import UrgentLeaveConfirm from './urgentLeaveConfirm';

import { checkLeave, leaveApply } from '@/api/curriculum';
import styles from './style.module.scss';

import { LeaveProps, LeaveTypeEnum } from '../../Home/interface';
import { log } from '@/api/log';
import { toDay, toHour } from '@/utils/utils';
import VerticalDivider from '@/pageComponents/Common/VerticalDivider';

const PrimaryApplyComponent = (props: LeaveProps) => {
  const { t, i18n } = useTranslation('home');
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const {
    classType,
    type,
    lessonTitle,
    lessonUnitTitle,
    courseScheduleId = '',
    lessonTeacherName,
    lessonStartDateTime,
    lessonEndDateTime,
    gtmPrefix,
    onClose,
  } = props;

  const [urgentLeaveConfirmVisible, setUrgentLeaveConfirmVisible] = useState(false);

  const submitDis = useMemo(() => {
    return !reason?.trim();
  }, [reason]);

  const beforeSubmit = async () => {
    if (type !== LeaveTypeEnum.URGENT_LEAVE_APPLY) {
      const { urgent } = await checkLeave({ courseScheduleId });
      return !urgent;
    }
    return true;
  };

  const urgentLeaveApplySer = () => {
    return leaveApply({ courseScheduleId, urgent: true, reason });
  };

  const onUrgentLeaveConfirmClose = () => {
    setUrgentLeaveConfirmVisible(false);
  };

  const onUrgentLeaveConfirmSubmit = () => {
    setUrgentLeaveConfirmVisible(false);
    onClose();
  };

  const handleSubmit = useCallback(async () => {
    const canSubmit = await beforeSubmit();
    if (!canSubmit) {
      setUrgentLeaveConfirmVisible(true);
      return;
    }

    try {
      setLoading(true);
      const urgent = type === LeaveTypeEnum.URGENT_LEAVE_APPLY;
      if (urgent) {
        gtmPrefix && log(gtmPrefix`urgent_leave_apply`);
      } else {
        gtmPrefix && log(gtmPrefix`leave_apply`);
      }
      await leaveApply({ courseScheduleId, urgent, reason });
      if (urgent) {
        gtmPrefix && log(gtmPrefix`urgent_leave_success`);
      } else {
        gtmPrefix && log(gtmPrefix`leave_success`);
      }
      setLoading(false);
      Toast.success(t('请假申请提交成功'));
      onClose();
    } catch {
      setLoading(false);
    }
  }, [courseScheduleId, reason, type, gtmPrefix]);

  const isUrgent = type === LeaveTypeEnum.URGENT_LEAVE_APPLY;

  const boxItemWidth = i18n.language === 'en' ? '80px' : 'auto';

  return (
    <div className={styles.boxWrapper}>
      <UrgentLeaveConfirm
        visible={urgentLeaveConfirmVisible}
        leaveApplySer={urgentLeaveApplySer}
        onClose={onUrgentLeaveConfirmClose}
        onSubmit={onUrgentLeaveConfirmSubmit}
      />
      {isUrgent && (
        <div className={styles.warningBox}>
          <img src={promptIconWarning.src} alt="" />
          <div className={styles.tipContainer}>
            <span className={styles.title}>{t('紧急请假提示')}</span>
            <span className={styles.tip} dangerouslySetInnerHTML={{ __html: t('紧急请假扣除课时') }} />
          </div>
        </div>
      )}
      <div className={cls(styles.boxItem, { [styles.mT20]: !isUrgent })}>
        <span style={{ width: boxItemWidth }}>{t('请假课程')}:</span>
        <span className={styles.itemTip}>
          {lessonUnitTitle?.split('-').join(' ')} - {lessonTitle}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('课程类型')}:</span>
        <span className={styles.itemTip}>{classType}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('主讲老师')}:</span>
        <span className={styles.itemTip}>{lessonTeacherName}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('上课时间')}:</span>
        <span className={cls(styles.itemTip, styles.bold)}>
          {toHour(lessonStartDateTime as string)} - {toHour(lessonEndDateTime as string)}
          <VerticalDivider />
          {toDay(lessonStartDateTime as string, i18n.language)}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }} className={styles.multipleLine}>
          {t('请假规则')}:
        </span>
        <div className={styles.itemTip}>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('请假规则提示1') }} />
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('请假规则提示2') }} />
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('请假规则提示3') }} />
        </div>
      </div>
      <div className={styles.line} />
      <div className={cls(styles.boxItem, styles.mB0)}>
        <span style={{ width: boxItemWidth }} className={styles.import}>
          {t('请假申请')}:
        </span>
        <textarea
          onInput={({ currentTarget }) => setReason(currentTarget.value)}
          className={styles.textarea}
          maxLength={200}
          placeholder={t('请假申请占位')}
        />
      </div>
      <div className={styles.inputWrapper}>
        <div className={styles.btnGroup}>
          <Button onClick={onClose} className={cls(styles.button, styles.cancel)}>
            {t('取消')}
          </Button>
          <Button
            onClick={handleSubmit}
            loading={loading}
            disabled={submitDis}
            color="red"
            shieldIcon
            className={cls(styles.button, styles.submit)}
          >
            {t('提交申请')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrimaryApplyComponent;
